var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useDispatch } from 'react-redux';
import { useWizard } from '@shared/ui/misc/Wizard';
import { useAppSelector } from '@app/store/Hooks';
import { closeSidebar, updateSidebarValues, } from '@store/slices/exchange';
import { closeSidebar as closeSidebarAppAction } from '@store/slices/application';
import { useCreateExchangeAccountMutation } from '@shared/api/exchange';
import { addToSidebarHistory, mapFieldsForCreateReq, removeFromSidebarHistory } from './utils';
var CreatePortfolioStepsEnum;
(function (CreatePortfolioStepsEnum) {
    CreatePortfolioStepsEnum[CreatePortfolioStepsEnum["SelectExchange"] = 0] = "SelectExchange";
    CreatePortfolioStepsEnum[CreatePortfolioStepsEnum["EnterKey"] = 1] = "EnterKey";
    // ConnectExchange = 1,
    CreatePortfolioStepsEnum[CreatePortfolioStepsEnum["SelectWallet"] = 2] = "SelectWallet";
    CreatePortfolioStepsEnum[CreatePortfolioStepsEnum["PortfolioSettings"] = 3] = "PortfolioSettings";
    CreatePortfolioStepsEnum[CreatePortfolioStepsEnum["Success"] = 4] = "Success";
    CreatePortfolioStepsEnum[CreatePortfolioStepsEnum["ErrorLimit"] = 5] = "ErrorLimit";
    CreatePortfolioStepsEnum[CreatePortfolioStepsEnum["CreationError"] = 6] = "CreationError";
})(CreatePortfolioStepsEnum || (CreatePortfolioStepsEnum = {}));
const usePortfolioCreate = () => {
    var _a;
    const { current, goTo } = useWizard();
    const { sidebarFormValues } = useAppSelector(state => state.exchange);
    const { currentUserProfile } = useAppSelector(state => state.profile);
    const dispatch = useDispatch();
    const [createReq, { isLoading: isCreatingExchangeAcc }] = useCreateExchangeAccountMutation();
    const handleClose = () => {
        dispatch(closeSidebarAppAction());
        dispatch(closeSidebar());
    };
    const handleExchangeCreate = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const mappedFields = mapFieldsForCreateReq(values);
        const res = yield createReq(mappedFields);
        if (res === null || res === void 0 ? void 0 : res.data) {
            goTo(CreatePortfolioStepsEnum.SelectWallet);
            return true;
        }
        return { error: res === null || res === void 0 ? void 0 : res.error };
    });
    const goToNextStep = (values) => {
        const updatedHistory = addToSidebarHistory(sidebarFormValues, current);
        switch (current) {
            case 0:
                if (values) {
                    // TODO: move this logic to redux slice
                    dispatch(updateSidebarValues(Object.assign(Object.assign({}, values), { stepsHistory: updatedHistory })));
                    // goTo(CreatePortfolioStepsEnum.ConnectExchange)
                    goTo(CreatePortfolioStepsEnum.EnterKey);
                }
                break;
            case 1:
                if (values) {
                    dispatch(updateSidebarValues(Object.assign(Object.assign({}, values), { stepsHistory: updatedHistory })));
                    goTo(CreatePortfolioStepsEnum.SelectWallet);
                }
                break;
            case 2:
                if (values) {
                    dispatch(updateSidebarValues(Object.assign(Object.assign({}, values), { stepsHistory: updatedHistory })));
                    goTo(CreatePortfolioStepsEnum.PortfolioSettings);
                }
                break;
            case 3:
                if (values) {
                    dispatch(updateSidebarValues(Object.assign(Object.assign({}, values), { stepsHistory: updatedHistory })));
                    // TODO: handle createPortfolioReq
                    goTo(CreatePortfolioStepsEnum.Success);
                }
                break;
            case 4:
            case 5:
            case 6:
                handleClose();
                break;
            default:
                break;
        }
    };
    const goToPrevStep = () => {
        const { updatedHistory, prevStep } = removeFromSidebarHistory(sidebarFormValues);
        switch (current) {
            case 0:
            case 1:
            case 2:
            case 3:
                dispatch(updateSidebarValues({ stepsHistory: updatedHistory }));
                if (typeof prevStep === 'number') {
                    goTo(prevStep);
                }
                break;
            default:
                break;
        }
    };
    const handleExchangeConnect = () => {
        const updatedHistory = addToSidebarHistory(sidebarFormValues, current);
        dispatch(updateSidebarValues({ stepsHistory: updatedHistory }));
        // goTo(CreatePortfolioStepsEnum.ConnectExchange)
        goTo(CreatePortfolioStepsEnum.SelectExchange);
    };
    return {
        goToNextStep,
        goToPrevStep,
        sidebarFormValues,
        handleExchangeConnect,
        handleExchangeCreate,
        handleClose,
        currentUserProfile,
        displayBackBtn: ((_a = sidebarFormValues === null || sidebarFormValues === void 0 ? void 0 : sidebarFormValues.stepsHistory) === null || _a === void 0 ? void 0 : _a.length) > 0,
        isVerified: true,
        isCreatingExchangeAcc,
    };
};
export default usePortfolioCreate;
