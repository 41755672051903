import * as yup from 'yup';
import { REGEXP_LATIN_SYMBOLS } from '@app/constants/validation';
import { ConnectExchangeErrorCodes } from '@shared/ui/sidebars/errorCodes';
// Invalid Api Key and Secret Key pair
const checkWallet = (translateFn, wallets) => (walletId) => {
    if (walletId.length) {
        const selectedWalletData = wallets.find(({ id }) => id === walletId);
        if (selectedWalletData) {
            const isEnoughAmount = selectedWalletData.amount > 100;
            // its necessary to have context here
            // eslint-disable-next-line func-names, consistent-return
            return yup.string().test('', 'check-amount', function () {
                if (!isEnoughAmount) {
                    return this.createError({
                        message: translateFn('portfolio.create_portfolio.wallet_errors.insufficient_amount', { ns: 'common' }),
                    });
                }
                return true;
            });
        }
        return yup.string().length(1, '');
    }
    return yup.string().length(1, '');
};
const checkGroupedWallets = (translateFn, groupedWallets) => (walletId) => {
    if (walletId.length) {
        const selectedWalletData = groupedWallets.reduce((acc, group) => {
            const isSelectedWallet = group.items.find(({ id }) => id === walletId);
            if (isSelectedWallet) {
                return isSelectedWallet;
            }
            return acc;
        }, null);
        if (selectedWalletData) {
            const isEnoughAmount = selectedWalletData.amount > 100;
            // its necessary to have context here
            // eslint-disable-next-line func-names, consistent-return
            return yup.string().test('', 'check-amount', function () {
                if (!isEnoughAmount) {
                    return this.createError({
                        message: translateFn('portfolio.create_portfolio.wallet_errors.insufficient_amount', { ns: 'common' }),
                    });
                }
                return true;
            });
        }
        return yup.string().length(1, '');
    }
    return yup.string().length(1, '');
};
export const selectWalletValidationSchema = (translateFn, wallets) => yup.object().shape({
    walletId: yup.lazy(checkWallet(translateFn, wallets)),
});
export const portfolioSettingsValidationSchema = (translateFn, wallets) => yup.object().shape({
    portfolioName: yup.string().min(1, ' ').max(30, ' '),
    walletId: yup.lazy(checkGroupedWallets(translateFn, wallets)),
});
export const enterKeyValidationSchema = (translateFn) => yup.object().shape({
    exchangeName: yup.string().min(1, translateFn('overview.connect_binance_exchange.enter_data.errors.should_not_be_empty')).matches(REGEXP_LATIN_SYMBOLS, translateFn('overview.connect_binance_exchange.enter_data.errors.only_latin')).max(50, 'overview.connect_binance_exchange.enter_data.errors.max_50_chars'),
    apiKey: yup.string().min(1, translateFn('overview.connect_binance_exchange.enter_data.errors.should_not_be_empty')),
    secretKey: yup.string().min(1, translateFn('overview.connect_binance_exchange.enter_data.errors.should_not_be_empty')),
});
export const handleResponseError = ({ translateFn, setError, error, exchangeType, }) => {
    if (error === null || error === void 0 ? void 0 : error.code) {
        if (error.code === ConnectExchangeErrorCodes[exchangeType].INVALID_KEYS) {
            setError('apiKey', {
                message: translateFn('portfolio.create_portfolio.steps.error.api_error.invalid_keys', { ns: 'common' }),
            });
            setError('secretKey', {
                message: translateFn('portfolio.create_portfolio.steps.error.api_error.invalid_keys', { ns: 'common' }),
            });
        }
    }
    return error;
};
